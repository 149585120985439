<!--
 * @Description: 
 * @Author: 六六
 * @Date: 2021-01-21 15:20:49
 * @LastEditTime: 2021-02-20 16:20:51
 * @有问题找百度，实在不行就删库
-->
<template>
  <section :class="{ equipment: !isPhone }">
    <div class="container" v-if="userInfo.isbound && userInfo.iscan == 1">
      <div class="main">
        <van-row type="flex" justify="center">
          <van-col span="22">
            <div class="prompt">
              <div
                id="issue_1"
                :class="{
                  issue: true,
                  danger: rules.issue_1,
                }"
              >
                <div class="issue-num">第1题</div>
                <div class="title">1.请先选择您的身份</div>
                <div class="content">
                  <van-radio-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesOne"
                  >
                    <van-radio name="企业用户">企业用户</van-radio>
                    <van-radio name="个人用户">个人用户</van-radio>
                  </van-radio-group>
                  <div class="error-rule" v-if="rules.issue_1">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div
                id="issue_2"
                :class="{
                  issue: true,
                  danger: rules.issue_2,
                }"
              >
                <div class="issue-num">第2题</div>
                <div class="title">
                  2.您曾通过以下哪项渠道听到或看到过小牛翻译的宣传？（可多选）
                </div>
                <div class="content">
                  <van-checkbox-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesTwo"
                  >
                    <van-checkbox name="朋友圈/微博" shape="square"
                      >朋友圈/微博</van-checkbox
                    >
                    <van-checkbox name="视频号/公众号" shape="square"
                      >视频号/公众号</van-checkbox
                    >
                    <van-checkbox name="知乎/抖音" shape="square"
                      >知乎/抖音</van-checkbox
                    >
                    <van-checkbox name="搜狐号/头条号" shape="square"
                      >搜狐号/头条号</van-checkbox
                    >
                    <van-checkbox name="补充" shape="square">补充</van-checkbox>
                  </van-checkbox-group>
                  <van-field v-model="quesTwoText" placeholder="请输入..." />
                  <div class="error-rule" v-if="rules.issue_2">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div
                id="issue_3"
                :class="{
                  issue: true,
                  danger: rules.issue_2,
                }"
              >
                <div class="issue-num">第3题</div>
                <div class="title">3.您主要使用云平台以下哪项服务模式？</div>
                <div class="content">
                  <van-radio-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesThree"
                  >
                    <van-radio name="API接口">API接口</van-radio>
                    <van-radio name="离线SDK">离线SDK</van-radio>
                    <van-radio name="私有部署">私有部署</van-radio>
                  </van-radio-group>
                  <div class="error-rule" v-if="rules.issue_3">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div
                id="issue_4"
                :class="{
                  issue: true,
                  danger: rules.issue_4,
                }"
              >
                <div class="issue-num">第4题</div>
                <div class="title">
                  4.您觉得目前小牛翻译云平台的首页设计是否能够让您简单快捷的找到所需信息？
                </div>
                <div class="content">
                  <van-radio-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesFour"
                  >
                    <van-radio name="可以">可以</van-radio>
                    <van-radio name="我觉得需要改进">我觉得需要改进</van-radio>
                  </van-radio-group>
                  <van-field v-model="quesFourText" placeholder="改进建议" />
                  <div class="error-rule" v-if="rules.issue_4">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div
                id="issue_5"
                :class="{
                  issue: true,
                  danger: rules.issue_5,
                }"
              >
                <div class="issue-num">第5题</div>
                <div class="title">5.平台内提供的流量套餐梯度是否合理？</div>
                <div class="content">
                  <van-radio-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesFive"
                  >
                    <van-radio name="我觉得合理">我觉得合理</van-radio>
                    <van-radio name="我觉得有改进空间"
                      >我觉得有改进空间</van-radio
                    >
                  </van-radio-group>
                  <van-field v-model="quesFiveText" placeholder="改进建议" />
                  <div class="error-rule" v-if="rules.issue_5">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div
                id="issue_6"
                :class="{
                  issue: true,
                  danger: rules.issue_6,
                }"
              >
                <div class="issue-num">第6题</div>
                <div class="title">
                  6.您是否参加过小牛翻译的活动？（可多选）
                </div>
                <div class="content">
                  <van-checkbox-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesSix"
                  >
                    <van-checkbox name="线上活动" shape="square"
                      >线上活动</van-checkbox
                    >
                    <van-checkbox name="线下活动" shape="square"
                      >线下活动</van-checkbox
                    >
                    <van-checkbox name="还没参加过，期待参加" shape="square"
                      >还没参加过，期待参加</van-checkbox
                    >
                    <van-checkbox name="还没参加过，没兴趣" shape="square"
                      >还没参加过，没兴趣</van-checkbox
                    >
                  </van-checkbox-group>
                  <div class="error-rule" v-if="rules.issue_6">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div
                id="issue_7"
                :class="{
                  issue: true,
                  danger: rules.issue_7,
                }"
              >
                <div class="issue-num">第7题</div>
                <div class="title">
                  7.您最希望通过参加一场活动收获以下哪项？（可多选）
                </div>
                <div class="content">
                  <van-checkbox-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesSeven"
                  >
                    <van-checkbox name="购买折扣" shape="square"
                      >购买折扣</van-checkbox
                    >
                    <van-checkbox name="实物奖励" shape="square"
                      >实物奖励</van-checkbox
                    >
                    <van-checkbox name="商业资源" shape="square"
                      >商业资源</van-checkbox
                    >
                    <van-checkbox name="宣传机会" shape="square"
                      >宣传机会</van-checkbox
                    >
                    <van-checkbox name="补充" shape="square">补充</van-checkbox>
                  </van-checkbox-group>
                  <van-field v-model="quesSevenText" placeholder="请输入..." />
                  <div class="error-rule" v-if="rules.issue_7">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div
                id="issue_8"
                :class="{
                  issue: true,
                  danger: rules.issue_8,
                }"
              >
                <div class="issue-num">第8题</div>
                <div class="title">
                  8.您认为目前的小牛翻译云平台更需要在哪些方面加强？（可多选）
                </div>
                <div class="content">
                  <van-checkbox-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesEight"
                  >
                    <van-checkbox name="页面设计" shape="square"
                      >页面设计</van-checkbox
                    >
                    <van-checkbox name="文案介绍" shape="square"
                      >文案介绍</van-checkbox
                    >
                    <van-checkbox name="翻译质量" shape="square"
                      >翻译质量</van-checkbox
                    >
                    <van-checkbox name="服务形式" shape="square"
                      >服务形式</van-checkbox
                    >
                    <van-checkbox name="5" shape="square">补充</van-checkbox>
                  </van-checkbox-group>
                  <van-field v-model="quesEightText" placeholder="请输入..." />
                  <div class="error-rule" v-if="rules.issue_8">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div
                id="issue_9"
                :class="{
                  issue: true,
                  danger: rules.issue_9,
                }"
              >
                <div class="issue-num">第9题</div>
                <div class="title">
                  9.您是否在使用小牛翻译云平台的过程中遇到过影响体验的问题？
                </div>
                <div class="content">
                  <van-radio-group
                    checked-color="#febbc4"
                    v-model="issueForm.quesNine"
                  >
                    <van-radio name="还没遇到过">还没遇到过</van-radio>
                    <van-radio name="有过，但可以理解"
                      >有过，但可以理解</van-radio
                    >
                    <van-field
                      v-model="quesNineText1"
                      placeholder="遇到的问题"
                    />
                    <van-radio name="3">遇到过很难接受的问题</van-radio>
                    <van-field
                      v-model="quesNineText2"
                      placeholder="遇到的问题"
                    />
                  </van-radio-group>
                  <div class="error-rule" v-if="rules.issue_9">
                    <van-icon name="info" color="#e1001e" />
                    <span>请选择选项</span>
                  </div>
                </div>
              </div>
              <div id="issue_10" class="issue">
                <div class="issue-num">第10题</div>
                <div class="title">10.请简单描述您的使用场景（非必答题）</div>
                <div class="content">
                  <van-field
                    v-model="issueForm.quesTen"
                    rows="5"
                    type="textarea"
                    placeholder="请输入..."
                    show-word-limit
                    style="margin-left: 0"
                  />
                </div>
              </div>
              <div
                id="issue_11"
                :class="{
                  issue: true,
                  danger: rules.issue_11,
                }"
              >
                <div class="issue-num">第11题</div>
                <div class="title">
                  11.对于云平台的产品（API接口、离线SDK、离线翻译本地部署）您有什么需求或建议？
                </div>
                <div class="content">
                  <van-field
                    v-model="issueForm.quesEleven"
                    rows="5"
                    type="textarea"
                    placeholder="请输入..."
                    show-word-limit
                    style="margin-left: 0"
                  />
                  <div class="error-rule" v-if="rules.issue_11">
                    <van-icon name="info" color="#e1001e" />
                    <span>请填写内容</span>
                  </div>
                </div>
              </div>

              <div class="submit-btn">
                <img
                  src="../assets/images/submit.png"
                  alt=""
                  @click="handleSubmit"
                />
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="no-bind" v-if="userInfo.isbound == 0">
      <div class="bind-box">
        <div class="form-info">
          <van-field
            v-model="accountForm.account"
            center
            placeholder="请输入平台账号"
            :class="accountError ? 'danger' : ''"
            @blur="accountRule"
          >
          </van-field>
          <span class="account">{{ accountMsg }}</span>
          <van-field
            v-model="accountForm.code"
            center
            placeholder="请输入验证码"
            :class="codeError ? 'danger' : ''"
            @blur="codeRule"
          >
            <template #button>
              <van-button
                size="small"
                style="color: #666"
                @click="sendCode"
                v-if="myCode"
                >发送验证码</van-button
              >
              <van-button size="small" style="color: #999" v-else>{{
                btntxt
              }}</van-button>
            </template>
          </van-field>
          <span class="account-code">{{ codeMsg }}</span>
        </div>
        <van-row class="row-btn" type="flex" justify="center">
          <van-col span="13">
            <div class="left" @click="handleRegister">没有账号，去注册</div>
          </van-col>
          <van-col span="8">
            <div class="right" @click="handleBind">绑定账号</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="take-success" v-if="userInfo.iscan == 2"></div>

    <dialog-model
      :type="dialogType"
      :dialogShow="dialogShow"
      @takeSurvey="takeSurvey"
      @changeShow="changeShow"
    ></dialog-model>
  </section>
</template>

<script>
import { GetCode, Wechat, Submit, Jsconfig } from "../util/api";
import { getWxPublicInfo, getUrlParam } from "../util/util";
import DialogModel from "./components/dialogModel";
import wx from "weixin-js-sdk";
export default {
  components: { DialogModel },
  data() {
    return {
      dialogType: 1,
      dialogShow: false,
      userInfo: {},
      issueForm: {
        state: "",
        quesTwo: [],
        quesSix: [],
        quesSeven: [],
        quesEight: [],
      },
      quesTwoText: "",
      quesFourText: "",
      quesFiveText: "",
      quesSevenText: "",
      quesEightText: "",
      quesNineText1: "",
      quesNineText2: "",

      rules: {
        issue_1: false,
        issue_2: false,
        issue_3: false,
        issue_4: false,
        issue_5: false,
        issue_6: false,
        issue_7: false,
        issue_8: false,
        issue_9: false,
        issue_10: false,
      },
      accountMsg: "",
      codeMsg: "",
      accountError: false,
      codeError: false,
      time: 0,
      btntxt: "",
      myCode: true,
      accountForm: {
        account: "",
        code: "",
      },
      accountReg: /^1[3456789]\d{9}$/,
      emailReg: /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+.)+[A-Za-z]{2,6}$/,
      isPhone: true,
      anTime: 0,
    };
  },
  mounted() {
    this.isPhone = this.$store.getters["userInfo/isPhone"];
    this.getInfo();
    this.shareToFriends();
    this.shareToFriendsCircle();
  },
  methods: {
    getInfo() {
      getWxPublicInfo("question", (res) => {
        if (res.error_code == -1) {
          this.dialogShow = true;
          this.dialogType = 0;
        } else if (res.error_code == -2) {
          window.location.href = this.$urls + "/zhuli/questionnaire";
        } else {
          this.dialogShow = false;
          this.userInfo = res;
          if (res.iscan == 1) {
            this.answerTimer();
          }
          this.link = this.$urls + "/zhuli/questionnaire";
          this.handleShare();
        }
      });
    },
    handleShare() {
      let obj = {
        url: window.location.href,
      };
      Jsconfig(obj).then((res) => {
        if (res.data.error_code == 0) {
          //配置参数
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
            ],
          });

          wx.error((res) => {});
        }
      });
    },
    shareToFriends() {
      wx.ready(() => {
        wx.onMenuShareAppMessage({
          title: "小牛翻译云平台有奖问卷", // 分享标题
          desc: "用户使用情况及用户满意度调研", // 分享描述
          link: this.link,
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.$urls + "/NiuTransWeChatPublic/shareimgae/wenjuan.png", // 分享图标
          type: "", // 分享类型,music、video或link，不填默认为link
          dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
          success: function () {},
        });
      });
    },
    shareToFriendsCircle() {
      wx.ready(() => {
        wx.onMenuShareTimeline({
          title: "小牛翻译云平台有奖问卷", // 分享标题
          link: this.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.$urls + "/NiuTransWeChatPublic/shareimgae/wenjuan.png", // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          },
        });
      });
    },
    handleSubmit() {
      this.issueForm.state = this.$store.getters["userInfo/stateVal"];
      this.issueForm.time = this.anTime;
      this.issueForm.from = getUrlParam("from");
      let arr = [];
      if (!this.issueForm.quesOne) {
        this.rules.issue_1 = true;
        arr.push("issue_1");
      } else {
        this.rules.issue_1 = false;
      }

      if (!this.issueForm.quesTwo.length) {
        this.rules.issue_2 = true;
        arr.push("issue_2");
      } else {
        this.rules.issue_2 = false;
      }

      if (!this.issueForm.quesThree) {
        this.rules.issue_3 = true;
        arr.push("issue_3");
      } else {
        this.rules.issue_3 = false;
      }

      if (!this.issueForm.quesFour) {
        this.rules.issue_4 = true;
        arr.push("issue_4");
      } else {
        this.rules.issue_4 = false;
      }

      if (!this.issueForm.quesFive) {
        this.rules.issue_5 = true;
        arr.push("issue_5");
      } else {
        this.rules.issue_5 = false;
      }

      if (!this.issueForm.quesSix.length) {
        this.rules.issue_6 = true;
        arr.push("issue_6");
      } else {
        this.rules.issue_6 = false;
      }

      if (!this.issueForm.quesSeven.length) {
        this.rules.issue_7 = true;
        arr.push("issue_7");
      } else {
        this.rules.issue_7 = false;
      }

      if (!this.issueForm.quesEight.length) {
        this.rules.issue_8 = true;
        arr.push("issue_8");
      } else {
        this.rules.issue_8 = false;
      }

      if (!this.issueForm.quesNine) {
        this.rules.issue_9 = true;
        arr.push("issue_9");
      } else {
        this.rules.issue_9 = false;
      }

      if (!this.issueForm.quesEleven) {
        this.rules.issue_11 = true;
        arr.push("issue_11");
      } else {
        this.rules.issue_11 = false;
      }

      let form = this.issueForm;
      if (arr.length == 0) {
        if (this.issueForm.quesTwo.indexOf("补充") > -1) {
          form.quesTwo =
            this.issueForm.quesTwo.join("#") + "#" + this.quesTwoText;
        } else {
          form.quesTwo = this.issueForm.quesTwo.join("#");
        }

        if (this.issueForm.quesSeven.indexOf("补充") > -1) {
          form.quesSeven =
            this.issueForm.quesSeven.join("#") + "#" + this.quesSevenText;
        } else {
          form.quesSeven = this.issueForm.quesSeven.join("#");
        }

        if (this.issueForm.quesEight.indexOf("补充") > -1) {
          form.quesEight =
            this.issueForm.quesEight.join("#") + "#" + this.quesEightText;
        } else {
          form.quesEight = this.issueForm.quesEight.join("#");
        }

        if (this.issueForm.quesFour == "我觉得需要改进") {
          form.quesFour = form.quesFour + "#" + this.quesFourText;
        }
        if (this.issueForm.quesFive == "我觉得有改进空间") {
          form.quesFive = form.quesFive + "#" + this.quesFiveText;
        }
        if (this.issueForm.quesNine == "有过，但可以理解") {
          form.quesNine = form.quesNine + "#" + this.quesNineText1;
        }
        if (this.issueForm.quesNine == "遇到过很难接受的问题") {
          form.quesNine = form.quesNine + "#" + this.quesNineText2;
        }
        form.quesSix = this.issueForm.quesSix.join("#");
        Submit(form).then((res) => {
          if (res.data.error_code == 0) {
            this.getInfo();
            clearInterval(this.timer);
          }
        });
      } else {
        this.jump(arr[0]);
      }
    },

    takeSurvey() {
      this.getInfo();
    },
    changeShow(val) {
      this.dialogShow = val;
    },
    jump(index) {
      // 用 class="d_jump" 添加锚点
      let jump = document.getElementById(index);
      let total = jump.offsetTop;
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }
      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },
    handleRegister() {
      window.location.href = "https://niutrans.com/register?from=question";
    },
    handleBind() {
      if (this.accountForm.account && this.accountForm.code) {
        if (!this.accountError && !this.codeError) {
          let obj = {
            state: this.$store.getters["userInfo/stateVal"],
            account: this.accountForm.account,
            type: this.accountReg.test(this.accountForm.account) ? 1 : 0,
            authcode: this.accountForm.code,
          };
          Wechat(obj).then((res) => {
            if (res.data.error_code == 0) {
              this.dialogType = 5;
              this.dialogShow = true;
            } else if (res.data.error_code == 10005) {
              this.codeMsg = "*验证码不正确";
              this.codeError = true;
            } else if (res.data.error_code == 10006) {
              this.dialogType = 4;
              this.dialogShow = true;
            } else {
              this.$toast(res.data.error_msg);
            }
          });
        }
      } else {
        this.accountRule();
        this.codeRule();
      }
    },
    accountRule() {
      if (!this.accountForm.account) {
        this.accountMsg = "*请填写账号";
        this.accountError = true;
      } else if (
        !this.accountReg.test(this.accountForm.account) &&
        !this.emailReg.test(this.accountForm.account)
      ) {
        this.accountMsg = "*账号格式不正确";
        this.accountError = true;
      } else {
        this.accountMsg = "";
        this.accountError = false;
      }
    },
    codeRule() {
      if (!this.accountForm.code) {
        this.codeMsg = "*请填写验证码";
        this.codeError = true;
      } else {
        this.codeMsg = "";
        this.codeError = false;
      }
    },
    handleCancel() {
      this.$emit("changeShow", false);
    },
    //发送验证码
    sendCode() {
      if (this.accountForm.account) {
        this.accountRule();
        if (!this.accountError) {
          let obj = {
            state: this.$store.getters["userInfo/stateVal"],
            account: this.accountForm.account,
            type: this.accountReg.test(this.accountForm.account) ? 1 : 0,
          };
          GetCode(obj).then((res) => {
            if (res.data.error_code == 0) {
              this.$toast("发送成功");
              this.time = 60;
              this.myCode = false;
              this.timer();
            } else {
              this.$toast(res.data.error_msg);
            }
          });
        }
      } else {
        this.accountMsg = "*请填写账号";
        this.accountError = true;
      }
    },
    //60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新发送";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.myCode = true;
        clearInterval(this.timer);
      }
    },

    //答题计时器
    answerTimer() {
      this.anTime++;
      setTimeout(this.answerTimer, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.answerTimer);
  },
};
</script>

<style lang="scss" scoped>
.equipment {
  .take-success {
    max-width: 640PX;
    margin: 0 auto;
    min-height: 1000PX !important;
  }
  .no-bind {
    max-width: 640PX;
    margin: 0 auto;
    min-height: 1000PX !important;
  }
}
.take-success {
  height: 100vh;
  min-height: 610PX;
  background: url("../assets/images/background-1.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.no-bind {
  height: 100vh;
  min-height: 610PX;
  background: url("../assets/images/background-2.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .bind-box {
    width: 100%;
    position: absolute;
    top: 42%;
    .form-info {
      width: 50%;
      margin: 0 auto;
      .danger {
        border: 1PX solid #e1001e !important;
      }
      .van-field {
        width: 92%;
        height: 26px;
        border: 1PX solid #f0cea9;
        background-color: transparent;
        padding: 0 5px;
        margin-left: 12px;
        border-radius: 2px;
        margin-bottom: 25px;
      }
      .account,
      .account-code {
        color: red;
        position: absolute;
        left: 28%;
      }
      .account {
        top: 26px;
      }
      .account-code {
        top: 77px;
      }
      .van-button--default {
        border: none;
        background-color: transparent;
        font-weight: 600;
      }
    }
    .row-btn {
      width: 58%;
      margin: 0 auto;
      .left,
      .right {
        text-align: center;
        height: 30px;
        line-height: 30px;
        border-radius: 20px;
        font-weight: 600;
      }
      .left {
        padding: 0 2px;
        border: 1PX solid #fe644c;
        color: #fe644c;
        margin-right: 5px;
      }
      .right {
        border: 1PX solid #fe644c;
        background-color: #fe644c;
        color: #fff;
      }
    }
  }
}
.container {
  height: 100%;
  background: url("../assets/images/qa.jpg") no-repeat;
  background-size: 100% 100%;
  z-index: -100;
  .main {
    height: 100%;
    .prompt {
      margin-top: 185%;
      text-align: left;
      .danger {
        background-image: url("../assets/images/qa-border-danger.png") !important;
      }
      .issue {
        height: 350px;
        border-radius: 10px;
        background: url("../assets/images/qa-border.png") no-repeat;
        background-size: 100%;
        padding: 25px 45px 0;
        .issue-num {
          text-align: center;
          color: #fff;
          font-size: 16px;
        }
        .title {
          font-size: 16px;
          font-weight: 600;
          margin-top: 30px;
          line-height: 1.5;
        }
        .content {
          font-size: 14px;
          margin-top: 15px;
          .error-rule {
            margin-top: -1px;
            i {
              font-size: 17px;
              vertical-align: text-bottom;
              margin-right: 10px;
              vertical-align: top;
            }
            span {
              color: #e1001e;
            }
          }
          .van-radio {
            margin-bottom: 8px;
            /deep/.van-radio__icon {
              font-size: 16px;
            }
            /deep/.van-radio__label {
              margin-left: 15px;
            }
            /deep/.van-radio__icon .van-icon {
              line-height: 1.2;
              border-color: #999;
            }
            /deep/.van-radio__icon--checked .van-icon {
              color: #333;
            }
          }
          .van-checkbox {
            margin-bottom: 8px;
            /deep/.van-checkbox__icon {
              font-size: 16px;
            }
            /deep/.van-checkbox__label {
              margin-left: 15px;
            }
            /deep/.van-checkbox__icon .van-icon {
              line-height: 1.2;
              border-color: #999;
            }
            /deep/.van-checkbox__icon--checked .van-icon {
              color: #333;
            }
          }
          .van-field {
            border: 1px solid;
            background-color: transparent;
            padding: 4px 5px;
            margin-left: 30px;
            border-radius: 2px;
            margin-bottom: 15px;
          }
        }
      }
      .submit-btn {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
      }
    }
  }

  //   @media only screen and (min-device-width: 768px) and (max-width: 1024px) {
  //  .issue {
  //         padding: 31px 45px 0 !important;
  //         height: 380px!important;
  //     }
  //   }
  @media screen and (min-device-width: 561px) {
    .issue {
      padding: 31px 45px 0 !important;
      height: 400px !important;
    }
  }
}

// @media only screen and (min-width: 561px) and (max-width: 767px) {
//   .prompt {
//     height: 145px !important;
//     padding: 50px 20px 0 !important;
//   }
//   .assistance {
//     height: 300px !important;
//     padding: 80px 20px 0 !important;
//   }
// }
</style>
